import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { BotContext } from '../../context/BotContext'
import { NavContext } from '../../context/NavContext'
import SectionTemplate from './SectionTemplate'

const windowAwait = typeof window !== 'undefined' ? window : ''
const documentAwait = typeof document !== 'undefined' ? document : ''

const ServicesSection = ({ expandedSection, onExpandedSectionChange, onMobileMenuClose }) => {
    const { contentfulOrderServices: { description, services } } = useContext(NavContext)
    const { setActiveBot, setBotShow } = useContext(BotContext)

    const handleBotActivation = () => {
        setActiveBot('ServicesLandBot')
        setBotShow(true)
    }

    const scrollToServicesSection = () => {
        const servicesTitle = documentAwait.getElementById('services-section-title')
        const servicesTitleY = servicesTitle.getBoundingClientRect().top + windowAwait.pageYOffset

        window.scrollTo({ top: servicesTitleY, behavior: 'smooth' })
    }

    const handleServiceClick = (heading) => async (event) => {
        onExpandedSectionChange(null)(event)

        if (heading === 'Office Design and Construction') {
            navigate('/services/office-design')
            return
        }
        else if(heading === "For Asset Owners") {
            navigate('/asset-owners')
            return
        }
        else if(heading === "Available Properties"){
            navigate('/available-properties')
            return
        }

        if (onMobileMenuClose) {
            onMobileMenuClose()
        }

        if (windowAwait.location.pathname !== '/') {
            await navigate('/')

            const timeout = setTimeout(() => {
                scrollToServicesSection()
                clearTimeout(timeout)
            }, 500)

            return
        }

        scrollToServicesSection()
    }

    return (
        <SectionTemplate
            name="Services"
            description={description}
            onAction={handleBotActivation}
            expandedSection={expandedSection}
            onExpandedSectionChange={onExpandedSectionChange}
            actionId="s-findoutmore"
        >
            {[services.slice(0, 2), services.slice(2, 4), services.slice(4, 6)].map((service) => (
                <div style={{ flex: '1 0 calc(25% - 4rem)' }} className="flex mx-8 my-4 md:m-8">
                    <div className="flex flex-col -m-4">
                        {service.map(({ heading, shortDescription, icon }) => (
                            <div
                                className="flex items-start m-2 cursor-pointer group"
                                onClick={handleServiceClick(heading)}
                            >
                                <img src={icon ? icon.file.url : null} alt={icon ? icon.title : ''} className="mr-4" />
                                <div>
                                    <h6
                                        id={`s-${heading.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`}
                                        className="text-base font-bold"
                                    >
                                        <span className="inline-block">
                                            {heading}
                                            <span
                                                style={{ height: '2px' }}
                                                className="duration-300 ease-in-out bg-primary block w-0 group-hover:w-full"
                                            />
                                        </span>
                                    </h6>
                                    <p className="text-sm leading-tight mt-1 mb-0">{shortDescription}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </SectionTemplate>
    )
}

ServicesSection.propTypes = {
    expandedSection: PropTypes.string,
    onExpandedSectionChange: PropTypes.func.isRequired,
    onMobileMenuClose: PropTypes.func,
}

ServicesSection.defaultProps = {
    expandedSection: null,
    onMobileMenuClose: null,
}

export default ServicesSection
