import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { BotContext } from '../../context/BotContext'
import { NavContext } from '../../context/NavContext'
import SectionTemplate from './SectionTemplate'

const LocationsSection = ({ expandedSection, onExpandedSectionChange }) => {
    const { contentfulOrderContinents: { description, continents } } = useContext(NavContext)
    const { setActiveBot, setBotShow } = useContext(BotContext)

    const handleBotActivation = () => {
        setActiveBot('LocationsLandBot')
        setBotShow(true)
    }

    // Calculating how many columns will be displayed
    const cityColumns = continents.reduce((acc, continent) => acc + (continent.localities.length >= 10 ? 2 : 1), 0);
    const continentsWithWidth = continents.reduce((acc, continent) => [
        ...acc,
        {
            ...continent,
            // if continent needs two columns (10+ items), doubling standard column width
            width: continent.localities.length >= 10 ? (75 / cityColumns * 2) : (75 / cityColumns)
        }
    ], []);

    return (
        <SectionTemplate
            name="Locations"
            description={description}
            onAction={handleBotActivation}
            expandedSection={expandedSection}
            onExpandedSectionChange={onExpandedSectionChange}
            actionId="l-findoutmore"
        >
            {continentsWithWidth.map(({ title, localities, width }) => (
                <div key={title} style={{ flex: `1 0 calc(${width}% - 4rem)` }} className="mx-8 my-4 md:m-8">
                    <h3 className="border-b-2 border-primary inline-block text-base mb-4">{title}</h3>
                    <div className="flex flex-col flex-wrap" style={{ maxHeight: '260px' }}>
                        {localities.map((item) => (
                            <div key={item} id={`l-${item.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`} className="cursor-pointer" onClick={handleBotActivation}>
                                <p className="text-base mb-0">
                                    <span className="inline-block group">
                                        {item}
                                        <span
                                            style={{ height: '2px', marginTop: '-4px' }}
                                            className="duration-300 ease-in-out bg-primary block w-0 group-hover:w-full"
                                        />
                                    </span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </SectionTemplate>
    )
}

LocationsSection.propTypes = {
    expandedSection: PropTypes.string,
}

LocationsSection.defaultProps = {
    expandedSection: null,
}

export default LocationsSection
