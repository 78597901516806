import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'


// For Netlify's Issues
const activeWindow = typeof window !== "undefined" ? window : null


export default function HubSpot() {


    // no service id in HubSpot
    const data = useStaticQuery(graphql`
        query HubSpot {
            contentfulExternalServiceIds(serviceName: {eq: "HubSpot"}) {
                active
            }
        }
    `)

    const { active } = data.contentfulExternalServiceIds


    useEffect(() => {
        if (active && activeWindow) {
            var _hsq = window._hsq = window._hsq || [];
            activeWindow.addEventListener('message', function (msg) {
                if (msg.data.topic === 'notifyHubSpot') {
                    _hsq.push(["identify", { email: msg.data.email }]);
                    _hsq.push(['setPath', '/landbot']);
                    _hsq.push(['trackPageView']);
                }
            });
        }
    })


    if (!active) return ""


    return (
        <script async defer id="hs-script-loader" src="https://js.hs-scripts.com/3001249.js"></script>
    )
}
