import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import LinkedIn from '../../images/social/linkedin.svg'
import Instagram from '../../images/social/instagram.svg'
import Dog from '../../images/other/dog.png'
import KontorLogo from '../../images/logo/logo-blue.svg'
import Person from '../../images/ui/KontorBeth.png'
import Arrow from '../../images/ui/arrow-2.svg'

const Footer = ({ className, addition }) => {
    const windowAwait = typeof window !== 'undefined' ? window : false
    const currentPath = windowAwait ? window.location.pathname : ''
    const [showTeamLink, setShowTeamLink] = useState(false)
    
    const showCookieBanner = () => {
        const _hsp = windowAwait._hsp || [];
        _hsp.push(['showBanner']);
    }
    
    
    useEffect(() => {
        if (windowAwait && !showTeamLink) {
            setShowTeamLink(!['/team', '/pay', '/pay2', '/404'].some((link) => currentPath.startsWith(link)))
        }
    }, [windowAwait, showTeamLink])

    return (
        <div className={`footer pb-4 px-6 md:px-16 lg:24 transition-quick mb-0 lg:mb-12 ${className}`}>
            {showTeamLink && (
                <div className="max-w-6xl mx-auto px-0 -mt-48 relative">
                    <img className="w-60 md:w-80" src={Person} alt="Person Celebrating getting a new office"/>
                    <Link
                        id="h-meettheteam"
                        to="/team"
                        style={{transform: 'rotate(-10deg)'}}
                        className="absolute bg-secondary text-primary border-2 border-primary h-32 md:h-40 w-32 md:w-40 top-48 -mt-4 md:mt-12 left-24 md:left-32 px-5 flex items-center text-center rounded-full md:flex flex-col hover:shadow-2xl transition hover:cursor-pointer"
                    >
                        <span className="mx-auto text-xs md:text-sm mt-6 md:mt-10 mb-2">
                            Meet the team with you every step of the way
                        </span>
                        <img className="animated pulse infinite" src={Arrow} alt="Arrow Icon"/>
                    </Link>
                </div>
            )}
            <div
                style={{minHeight: 350}}
                className="relative w-lg bg-secondary text-primary p-6 mx-auto md:p-5 lg:p-16 lg:mx-auto max-w-6xl"
            >
                <div className="flex flex-wrap" style={{minHeight: 280}}>
                    <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 lg:px-3 px-4 md:pr-10 mb-4">
                        <h3 className="mb-4 mh-8 font-bold">Always at your service!</h3>
                        <p className="leading-snug">
                            If you have a question, comment, or need advice on anything office related,
                            we would love to hear from you.
                        </p>
                    </div>
                    <div className="w-full flex flex-col  sm:w-1/2 md:w-1/2 lg:w-1/4 lg:px-3 px-4  md:pr-10 sm:mb-4">
                        <h3 className="hidden sm:block flex-grow-0 font-bold mh-8 mb-4">Call us</h3>
                        <p className="hidden sm:block  flex-grow-0  mb-4 leading-snug">
                            Our awesome team is here to help you.
                        </p>
                        <p className="flex-grow-0 sm:mb-4 leading-snug">
                            Monday-Friday, <span className="block sm:inline mb-2 sm:mb-0 "><br className="hidden md:block"/>9am to 6pm GMT & EST.</span>
                        </p>
                        <a className="hover:underline flex-grow flex items-end leading-snug" href="tel:+44(0)2037704279" style={{ height: '48px'}}>
                            <p className=" inline font-bold mb-0">UK &nbsp;</p>
                            <p className=" underline font-bold mb-0">+44 (0)20 3770 4279</p>
                        </a>
                        <a className="hover:underline flex-grow flex items-end leading-snug" href="tel:+19174350990" style={{ height: '48px'}}>
                            <p className=" inline font-bold mb-0">USA &nbsp;</p>
                            <p className=" underline font-bold mb-0">+1 (646) 675 5020</p>
                        </a>
                    </div>
                    <div className="w-full flex flex-col  sm:w-1/2 md:w-1/2 lg:w-1/4 lg:px-3 px-4 md:pr-10 mb-4">
                        <h3 className="hidden sm:block flex-grow-0 font-bold mh-8 mb-4">Email us</h3>
                        <p className="hidden sm:block flex-grow-0 leading-snug">
                            Drop us a note anytime. When our doors are open, we'll get back to you within 1 hour.
                        </p>
                        <a className="font-bold underline flex-grow flex items-end" href="mailto:hello@kontor.com" style={{ height: '48px'}}>
                            <p className="mb-0">hello@kontor.com</p>
                        </a>
                    </div>
                    <div className="w-full flex flex-col justify-between sm:w-1/2 md:w-1/2 lg:w-1/4 lg:px-3 px-4 md:pr-10 mb-4">
                        <h3 className="hidden sm:block font-bold flex-grow-0  mb-4">Social... us</h3>
                        <p className="hidden sm:block flex-grow-0 leading-snug ">
                            See what we're up to on Instagram (obligatory office dog pics included) and LinkedIn.
                        </p>
                        <div className="flex flex-grow items-end mt-12 md:mt-4">
                            <a
                                href="https://www.linkedin.com/company/kontor-com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="LinkedIn"
                                style={{ width: '48px', height: '48px'}}
                            >
                                <img className="mr-4" src={LinkedIn} alt="LinkedIn Logo"/>
                            </a>
                            <a href="https://www.instagram.com/kontor_com/" style={{ width: '48px', height: '48px'}}>
                                <img src={Instagram} alt="Instagram Logo"/>
                            </a>
                        </div>
                    </div>
                    <img
                        className="absolute bottom-1/4 right-0"
                        style={{ width: 110 }}
                        src={Dog}
                        alt="Office Dog"
                    />
                </div>
                {addition}
            </div>
            <div className="text-sm max-w-6xl mx-auto sm:text-right text-center mt-4 text-primary md:text-xs">
                <Link id="h-privacypolicy" to="/privacy-policy" className="hover:underline mx-1 md:mx-2 ">PRIVACY POLICY</Link> |
                <Link id="h-cookiepolicy" to="/cookie-policy" className="hover:underline mx-1 md:mx-2 ">COOKIE POLICY</Link> |
                <button type="button" id="hs_show_banner_button" className="hover:underline"
                style={{ borderRadius: "3px", padding: "10px 16px", color: "#27348b",
                    fontFamily: "inherit", fontSize: "inherit", fontWeight: "normal", lineHeight: "inherit",
                    textAlign: "left", textShadow: "none"}}
                onClick={() => showCookieBanner()}
                >
                COOKIE SETTINGS
                </button> |
                <Link id="h-terms" to="/terms-and-conditions" className="hover:underline mx-1 md:ml-2 md:mr-0 ">TERMS</Link>
            </div>
            <div className="flex align-center">
                <img
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-offset="-200"
                    data-aos-delay="800"
                    src={KontorLogo}
                    alt="Kontor Logo"
                    className="my-8 md:hidden w-3/4 mx-auto max-w-md"
                />
                <img
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-offset="0"
                    data-aos-delay="0"
                    src={KontorLogo}
                    alt="Kontor Logo"
                    className="hidden md:block md:my-20 md:px-4 md:w-1/3 lg:w-1/4 mx-auto max-w-md"
                />
            </div>
        </div>
    )
}

Footer.propTypes = {
    className: PropTypes.string,
    footerAddition: PropTypes.node,
}

Footer.defaultProps = {
    className: '',
    footerAddition: null,
}

export default Footer
