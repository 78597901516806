import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Arrow from '../../images/ui/arrow-2.svg'
import ArrowWhite from '../../images/ui/arrow-2-white.svg'

const Button = props => {

    const { arrow, children, type, style, className, linkTo, ...restProps } = props;


    const renderArrow = (arrow) => {
        if (!arrow) return ""
        return <img
            src={type === "primary" ? ArrowWhite : Arrow}
            alt="Button Arrow"
            className="inline pl-4 animated infinite pulse"
        />
    }


    // Button Class
    const typeClasses = type === "primary" ? "bg-primary text-white border-2 border-secondary" : "bg-secondary text-primary border-2 border-primary"
    const buttonClasses = "p-3 my-2 inline-block flex-auto flex-wrap justify-between shadow-md hover:shadow-2xl transition-quick cursor-pointer"

    if (!linkTo) {
        return <div
        {...restProps}
        className={`${typeClasses} ${buttonClasses} ${className}`}
        style={style}
        role="button"
        tabIndex={0}
        >
            {children}
            {renderArrow(arrow)}
        </div>
    }

    // Tests if link is inside or outside the website 
    // If it starts with a "/" it is considered internal 
    // The rest are external
    const internal = /^\/(?!\/)/.test(linkTo)


    if (internal) {
        return (
            <Link
                to={linkTo}
                className={`${typeClasses} ${buttonClasses} ${className}`}
                style={style}
                tabIndex={0}
            >
                {children}
                {renderArrow(arrow)}
            </Link>
        )
    }
    return (
        <a  
            href={linkTo}
            target="_blank" rel="noopener noreferrer"
            className={`${typeClasses} ${buttonClasses} ${className}`}
            style={style}
            role="button"
            tabIndex={0}
        >
            {children}
            {renderArrow(arrow)}
        </a>)
}

Button.defaultProps = {
    arrow: true,
    type: "primary"
}

Button.propTypes = {
    arrow: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    type: PropTypes.oneOf(["primary", "secondary"]),
    className: PropTypes.string,
    style: PropTypes.object,
    linkTo: PropTypes.string
}

export default Button
