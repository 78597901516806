import React, { useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const NavContext = React.createContext(null)

export default ({ children }) => {
  const [menuActive, setMenuActive] = useState(false)
  const [fullProfileOpen, setFullProfileOpen] = useState(false)
  const [animationOver, setAnimationOver] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const { contentfulOrderServices, contentfulOrderContinents } = useStaticQuery(graphql`
      query {
          contentfulOrderServices(contentful_id: { eq: "5KqUgRcIZWaz9xIPwghioP" }) {
              description
              services {
                  heading
                  description
                  shortDescription
                  icon {
                      title
                      file {
                          url
                      }
                  }
              }
          }
          contentfulOrderContinents(contentful_id: { eq: "6bWpIadC403PyoJeOi2NhW" }) {
              description
              continents {
                  title
                  localities
              }
          }
      }
  `)

  const value = useMemo(() => ({
    menuActive,
    setMenuActive,
    fullProfileOpen,
    setFullProfileOpen,
    animationOver,
    setAnimationOver,
    setRefresh,
    refresh,
    contentfulOrderServices,
    contentfulOrderContinents,
  }), [
    menuActive,
    setMenuActive,
    fullProfileOpen,
    setFullProfileOpen,
    animationOver,
    setAnimationOver,
    setRefresh,
    refresh,
    contentfulOrderServices,
    contentfulOrderContinents,
  ]);

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}