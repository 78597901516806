import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { NavContext } from '../../context/NavContext'
import { BotContext } from '../../context/BotContext'
import MobileMenu from './MobileMenu'
import DesktopMenu from './DesktopMenu'
import BotButton from '../../landBot/BotButton'

const NavBar = ({ menuItems, activePage, location, mainButton }) => {
    const [route, setRoute] = useState(activePage)
    const [expandedSection, setExpandedSection] = useState(null)
    const { menuActive, setMenuActive, fullProfileOpen } = useContext(NavContext)
    const { botShow } = useContext(BotContext)
    const containerRef = useRef(null)

    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setExpandedSection(null)
        }
    }, [containerRef])

    const handleExpandedSectionChange = useCallback((name) => (event) => {
        if (name && name !== expandedSection) {
            setExpandedSection(name)
            return
        }

        if (!name && (!event.relatedTarget || !event.relatedTarget.className.includes('header-element'))) {
            setExpandedSection(null)
        }
    }, [expandedSection])

    useEffect(() => {
        // Manage team full profile 
        if (fullProfileOpen) {
            return setMenuActive(false)
        }

        // Manages route memory
        if (route !== activePage) {
            setRoute(activePage)
        }

        // Manages the changes in route and if navigation happens then calls the menu back
        if (!menuActive && route === activePage && !botShow) {
            setMenuActive(true)
        }
    }, [menuActive, activePage, route, setMenuActive, fullProfileOpen, location.state, botShow])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside])

    return (
        <div ref={containerRef}>
            <DesktopMenu
                menuItems={menuItems}
                activePage={activePage}
                expandedSection={expandedSection}
                onExpandedSectionChange={handleExpandedSectionChange}
                mainButton={mainButton}
            />
            <MobileMenu
                menuItems={menuItems}
                expandedSection={expandedSection}
                onExpandedSectionChange={handleExpandedSectionChange}
                mainButton={mainButton}
            />
        </div>
    )
}

NavBar.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            path: PropTypes.string,
        })
    ).isRequired,
    activePage: PropTypes.string,
    mainButton: PropTypes.node,
}

NavBar.defaultProps = {
    activePage: null,
    mainButton: (
        <BotButton
            id="h-lb-search"
            type="secondary"
            className="py-2 relative text-2xl md:text-xl shadow-charlie whitespace-no-wrap px-3 mt-8 md:mt-2"
        >
            Find your next office
        </BotButton>
    ),
}

export default NavBar
