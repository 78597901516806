import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'


// For Netlify's issues
const activeWindow = typeof window !== "undefined" ? window : null



export default function GoogleAnalytics() {

    const data = useStaticQuery(graphql`
    query Google {
        allContentfulExternalServiceIds(filter: {serviceName: {in: ["GoogleAnalytics", "GoogleOptimised", "GoogleTags"]}}) {
            nodes {
            serviceName
              serviceId
              active
            }
          }
      }
    `)

    // Filtering out the optimised ids and the analytics
    const googleOptimised = data.allContentfulExternalServiceIds.nodes.filter(node => node.serviceName === "GoogleOptimised")[0]
    const googleAnalytics = data.allContentfulExternalServiceIds.nodes.filter(node => node.serviceName === "GoogleAnalytics")[0]
    const googleTags = data.allContentfulExternalServiceIds.nodes.filter(node => node.serviceName === "GoogleTags")[0]


    useEffect(() => {
        if (activeWindow &&
            googleOptimised.active &&
            googleAnalytics.active
        ) {

            // NB Must expose 'dataLayer' to global scope, so that GA picks it up!
            activeWindow.dataLayer = activeWindow.dataLayer || [];
            function gtag() { activeWindow.dataLayer.push(arguments); }

            gtag('js', new Date());
            gtag('config', googleAnalytics.serviceId, {
                'optimize_id': googleOptimised.serviceId,
                'custom_map': { 'dimension1': 'route' },
                'route': 'headline'
            });

            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });

            activeWindow.addEventListener('message', function (msg) {
                if (msg.data.topic === 'gaEvent') {
                    gtag('event', msg.data.action, { 'event_category': msg.data.category })
                }
            });

            const _hsp = activeWindow._hsp = activeWindow._hsp || [];

            _hsp.push(['addPrivacyConsentListener', function (consent) {
                const hasAnalyticsConsent = consent && (consent.allowed || (consent.categories && consent.categories.analytics));
                const hasAdsConsent = consent && (consent.allowed || (consent.categories && consent.categories.advertisement));

                gtag('consent', 'update', {
                    'ad_storage': hasAdsConsent ? 'granted' : 'denied',
                    'analytics_storage': hasAnalyticsConsent ? 'granted' : 'denied',
                    'ad_user_data': hasAdsConsent ? 'granted' : 'denied',
                    'ad_personalization': hasAdsConsent ? 'granted' : 'denied'
                });
                // for debug purposes only will remove     
            }]);


            // Expose 'gtag' globally (hacky!)
            activeWindow.gtag = gtag;
        }
    }, [googleOptimised, googleAnalytics])

    if (!googleOptimised.active || !googleAnalytics.active) return ""


    return [
        <script src={`https://www.googleoptimize.com/optimize.js?id=${googleOptimised.serviceId}`} />,
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics.serviceId}`} />,
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTags.serviceId}`}></script>
    ]
}
