import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useSpring, animated } from 'react-spring'

import KontorLogo from '../../../images/logo/logo-blue.svg'
import Arrow from '../../../images/ui/arrow.svg'
import { NavContext } from '../../context/NavContext'
import BotButton from '../../landBot/BotButton'
import ServicesSection from './ServicesSection'
import LocationsSection from './LocationsSection'

const DesktopMenu = ({ menuItems, activePage, expandedSection, onExpandedSectionChange, mainButton }) => {
    const { menuActive } = useContext(NavContext)
    const animateNav = useSpring({
        config: { duration: 400 },
        top: menuActive ? 0 : -50,
        opacity: menuActive ? 1 : 0,
    })

    return (
        <animated.div style={animateNav} className={`hidden md:block fixed w-full pt-4 text-primary bg-bgWhite z-50 transition-quick ${expandedSection ? 'shadow-2xl' : ''}`}>
            <div className="items-center flex justify-between lg:px-16 sm:px-8">
                <div className="flex-grow">
                    <div style={{ width: 200 }}>
                        <Link className="mw-sm " to="/" state={{ fromSite: true }} style={{ width: 200 }}>
                            <img src={KontorLogo} alt="Kontor Logo" />
                        </Link>
                    </div>
                </div>
                <div className="flex flex-grow justify-end">
                    <div className="flex flex-grow justify-between items-center max-w-2xl mr-8">
                        {menuItems.map((item) => item.path
                            ? (
                                <Link
                                    id={`h-${item.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`}
                                    key={item.name}
                                    to={item.path}
                                    className="hover:underline p-2 xl:pr-1"
                                >
                                    <h2 className={`text-base uppercase ${activePage === item.path ? 'font-bold' : 'font-normal'}`}>
                                        <span className="inline-block group" style={{ marginBottom: '-6px' }}>
                                            {item.name}
                                            <span
                                                style={{ height: '2px', marginTop: '4px' }}
                                                role="presentation"
                                                className={`duration-300 ease-in-out bg-secondary block w-0 group-hover:w-full ${activePage === item.path ? 'w-full' : ''}`}
                                            />
                                        </span>
                                    </h2>
                                </Link>
                            ) : (
                                <div
                                    id={`h-${item.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`}
                                    key={item.name}
                                    className="flex items-center cursor-pointer decoration-2 hover:underline p-2 h-full"
                                    onMouseEnter={onExpandedSectionChange(item.name)}
                                    onMouseLeave={onExpandedSectionChange()}
                                >
                                    <h2 className="text-base uppercase mr-2">
                                        <span
                                            className="inline-block group"
                                            style={{
                                                marginBottom: '-6px',
                                                width: 'calc(100% + 2rem)'
                                            }}
                                        >
                                            {item.name}
                                            <span
                                                style={{ height: '2px', marginTop: '4px' }}
                                                role="presentation"
                                                className={`duration-300 ease-in-out bg-secondary block w-0 group-hover:w-full ${expandedSection === item.name ? 'w-full' : ''}`}
                                            />
                                        </span>
                                    </h2>
                                    <img
                                        className="transition-quick"
                                        style={{ transform: expandedSection === item.name ? '' : 'rotate(-180deg)' }}
                                        src={Arrow}
                                        alt="Arrow - Open Close Indicator"
                                    />
                                </div>
                            )
                        )}
                    </div>
                    <div className="flex-shrink-0">{mainButton}</div>
                </div>
            </div>
            <div
                className="w-full overflow-hidden sm:hidden md:block"
                style={{
                    opacity: expandedSection ? 1 : 0,
                    height: expandedSection ? '100%' : 0,
                    maxHeight: expandedSection ? '1000px' : 0,
                    transition: expandedSection ? '1s' : '0.5s',
                    marginTop: expandedSection ? 0 : '1rem',
                }}
            >
                <ServicesSection expandedSection={expandedSection} onExpandedSectionChange={onExpandedSectionChange} />
            </div>
        </animated.div>
    )
}

DesktopMenu.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            path: PropTypes.string,
        })
    ).isRequired,
    activePage: PropTypes.string,
    expandedSection: PropTypes.string,
    onExpandedSectionChange: PropTypes.func.isRequired,
    mainButton: PropTypes.node,
}

DesktopMenu.defaultProps = {
    activePage: null,
    expandedSection: null,
    mainButton: (
        <BotButton
            id="h-lb-search"
            type="secondary"
            className="py-2 relative text-2xl md:text-xl shadow-charlie whitespace-no-wrap px-3 mt-8 md:mt-2"
        >
            Find your next office
        </BotButton>
    ),
}

export default DesktopMenu
