import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { animated } from 'react-spring'

import KontorLogo from '../../../images/logo/logo-blue.svg'
import BurgerMenu from '../../../images/ui/burger-menu.svg'
import CrossMenu from '../../../images/ui/cross-menu.svg'
import Arrow from '../../../images/ui/arrow.svg'

import BotButton from "../../landBot/BotButton"
import ServicesSection from './ServicesSection'

// Prevents errors when deploying to Netlify (document / window undefined during deployment process)
const documentAwait = typeof document !== 'undefined' ? document : null

const MobileMenu = ({ menuItems, onExpandedSectionChange, expandedSection, mainButton }) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const [scrollOffset, setScrollOffset] = useState(0)

    const handleScroll = () => setScrollOffset(window.pageYOffset)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // Prevents scrolling on iOS
    const handleIosScroll = (event) => {
        if (menuOpen) {
            event.preventDefault()
        }
    }

    // Prevents scrolling else where
    if (menuOpen && documentAwait) {
        documentAwait.getElementsByTagName('html')[0].style.overflow = 'hidden'
    }

    if (!menuOpen && documentAwait) {
        documentAwait.getElementsByTagName('html')[0].style.overflow = 'scroll'
    }

    const handleMobileMenuToggle = () => {
        setMenuOpen((open) => !open)

        if (menuOpen) {
            onExpandedSectionChange(null)()
        }
    }

    return (
        <>
            {/* mobile view - menu closed */}
            <animated.div
                className={`md:hidden p-4 fixed flex items-center justify-between bg-bgWhite w-full transition-quick ${scrollOffset ? 'shadow-md' : ''}`}
                style={{
                    zIndex: 999,
                    opacity: menuOpen ? 0 : 1,
                }}
            >
                <div style={{ width: 200 }}>
                    <Link className="mw-sm " to="/" state={{ fromSite: true }} style={{ width: 200 }}>
                        <img src={KontorLogo} alt="Kontor Logo" />
                    </Link>
                </div>
                <img
                    style={{
                        opacity: menuOpen ? 0 : 1,
                        transform: menuOpen ? 'rotate(45deg)' : 'rotate(0deg)',
                        transition: menuOpen ? '0.1s' : '0.5s'
                    }}
                    onClick={handleMobileMenuToggle}
                    src={BurgerMenu}
                    alt="Open navigation menu icon"
                />
            </animated.div>
            {/* menu open */}
            <div
                className="bg-white fixed top-0 md:hidden"
                style={{
                    right: 0,
                    opacity: menuOpen ? 1 : 0,
                    transition: '0.5s',
                    zIndex: menuOpen ? 999 : -1,
                    width: '100%',
                }}
            >
                <div className="flex items-center justify-between p-4">
                    <Link className="mw-sm" to="/" state={{ fromSite: true }} style={{ width: 200 }}>
                        <img src={KontorLogo} alt="Kontor Logo" />
                    </Link>
                    <img
                        style={{
                            opacity: menuOpen ? 1 : 0,
                            transform: menuOpen ? 'rotate(0deg)' : 'rotate(-60deg)',
                            transition: menuOpen ? '0.5s' : '0.1s',
                        }}
                        onClick={handleMobileMenuToggle}
                        src={CrossMenu}
                        alt="Cross menu icon"
                    />
                </div>
                <div
                    onTouchMove={handleIosScroll}
                    className="flex flex-col px-4 text-primary h-screen overflow-auto"
                    style={{
                        opacity: menuOpen ? 1 : 0,
                        marginTop: menuOpen ? '1rem' : '2rem',
                        transition: '0.5s 0.25s',
                    }}
                >
                    {menuItems.map((item) => item.path
                        ? (
                            <Link
                                id={`h-${item.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`}
                                key={item.name}
                                to={item.path}
                                className="p-4 border-t-2"
                                state={{ fromSite: true }}
                            >
                                <h3 className="uppercase">{item.name}</h3>
                            </Link>
                        ) : (
                            <Fragment key={item.name}>
                                <h3
                                    id={`h-${item.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`}
                                    onClick={onExpandedSectionChange(item.name)}
                                    className={`text-primary border-t-2 flex justify-between p-4 uppercase transition-quick ${expandedSection === item.name ? 'bg-gray-200 border-b-2' : ''}`}
                                >
                                    {item.name}
                                    <img
                                        className="ml-4 transition-quick"
                                        style={{ transform: expandedSection === item.name ? '' : 'rotate(-180deg)' }}
                                        src={Arrow}
                                        alt="Arrow - Open Close Indicator"
                                    />
                                </h3>
                                {item.name === 'Services' && (
                                    <div
                                        style={{
                                            opacity: expandedSection ? 1 : 0,
                                            maxHeight: expandedSection ? '5000px' : 0,
                                            transition: expandedSection ? '1s' : '0.5s',
                                        }}
                                    >
                                        <ServicesSection
                                          expandedSection={expandedSection}
                                          onExpandedSectionChange={onExpandedSectionChange}
                                          onMobileMenuClose={handleMobileMenuToggle}
                                        />
                                    </div>
                                )}
                            </Fragment>
                        )
                    )}
                    <div>{mainButton}</div>
                </div>
            </div>
        </>
    )
}

MobileMenu.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            path: PropTypes.string,
        })
    ).isRequired,
    activePage: PropTypes.string,
    expandedSection: PropTypes.string,
    onExpandedSectionChange: PropTypes.func.isRequired,
    mainButton: PropTypes.node,
}

MobileMenu.defaultProps = {
    activePage: null,
    expandedSection: null,
    mainButton: (
        <BotButton
            id="h-lb-search"
            type="secondary"
            className="py-2 relative text-2xl md:text-xl shadow-charlie whitespace-no-wrap px-3 mt-8 md:mt-2"
        >
            Find your next office
        </BotButton>
    ),
}

export default MobileMenu
