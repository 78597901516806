import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'


// For Netlify's Issues
const activeDocument = typeof document !== "undefined" ? document : null
const activeWindow = typeof window !== "undefined" ? window : null

const  LinkedIn = (props) => {

    const data = useStaticQuery(graphql`
    query LinkedInId {
        contentfulExternalServiceIds(serviceName: {eq: "LinkedIn"}) {
            serviceId
            active
        }
      }
    `)

    const { serviceId, active } = data.contentfulExternalServiceIds
    
    
    // console.log(serviceId, active)


    useEffect(() => {
        if (active && activeWindow && activeDocument) {
            // LinkedIn Part 1
            const _linkedin_partner_id = serviceId;
            activeWindow._linkedin_data_partner_ids = activeWindow._linkedin_data_partner_ids || [];
            activeWindow._linkedin_data_partner_ids.push(_linkedin_partner_id);


            // LinkedIn Part 2
            var s = activeDocument.getElementsByTagName("script")[0];
            var b = activeDocument.createElement("script");
            b.type = "text/javascript"; b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
        }
    })


    // Handle disabled
    if (!active) return ""
    return (
        <noscript>
        {`
            <img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=${serviceId}&fmt=gif" />
        `}
        </noscript>
    )
}

export default LinkedIn