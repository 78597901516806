import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import Button from '../general/Button'
import { BotContext } from '../context/BotContext'

const BotButton = ({ botName, ...restProps }) => {
    const { setBotShow, setActiveBot } = useContext(BotContext)

    const handleClick = useCallback(() => {
        setActiveBot(botName)
        setBotShow(true)
    }, [botName, setBotShow, setActiveBot])

    return <Button onClick={handleClick} {...restProps} />
}

BotButton.propTypes = {
    botName: PropTypes.string,
}

BotButton.defaultProps = {
    botName: 'LandBot',
}

export default BotButton
