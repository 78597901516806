import React from 'react'
import PropTypes from 'prop-types'

import ArrowWhite from '../../../images/ui/arrow-2-white.svg'

const FindOutMoreButton = ({ id, onClick, className }) => (
    <div
        id={id}
        onClick={onClick}
        className={`text-white bg-primary shadow-md hover:shadow-2xl my-2 transition-quick cursor-pointer py-2 text-xl px-4 ${className}`}
    >
        Find out more
        <img src={ArrowWhite} alt="Button Arrow" className="pl-4 inline" />
    </div>
)

FindOutMoreButton.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
}

FindOutMoreButton.defaultProps = {
    className: '',
    onClick: null,
}

export default FindOutMoreButton
