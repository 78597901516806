import React, { useState } from 'react';


export const BotContext = React.createContext(null)

export default ({ children }) => {
    const [botShow, setBotShow] = useState(false);
    const [activeBot, setActiveBot] = useState("LandBot");
    const [showOnLanding, setShowOnLanding] = useState(true);


    return <BotContext.Provider value={{ botShow, setBotShow, showOnLanding, setShowOnLanding, activeBot, setActiveBot }}>{children}</BotContext.Provider>
}