import React, { useState } from 'react'
import PropTypes from 'prop-types'

const CloseBot = props => {

    const { color, botShow, setBotShow, setActiveBot, ...restProps } = props;
    
    const [ hover, setHover ] = useState(false)


    return (

        <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
            setBotShow(false);

            const timeout = setTimeout(() => {
                setActiveBot('LandBot');
                clearTimeout(timeout);
            }, 1000);
        }}
        style={{ opacity: botShow ? 1 : 0, bottom: botShow ? '0px' : '0px' }}
        className="fixed top-8 right-8 z-9999 border-2 border-primary rounded-full p-3 h-12 w-12  hover:bg-primary transition-quick hover:text-secondary hover:cursor-pointer ">
        <svg 
      
        {...restProps}
        width="22px" height="22px" viewBox="0 0 22 22" version="1.1" role='presentation'>
            <g id="Mobile-V1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                <g id="Splash-carousel-Copy" transform="translate(-275.000000, -16.000000)" stroke={hover ? "#FECC02" : "#27348B"} strokeWidth="2">
                    <g id="Group" transform="translate(273.000000, 15.051256)">
                        <g id="cross-menu" transform="translate(0.000000, -0.000000)">
                            <path d="M25.325,11.9487437 L-0.325,11.9487437" id="Line" transform="translate(12.500000, 11.948744) rotate(-315.000000) translate(-12.500000, -11.948744) "></path>
                            <path d="M12.6237437,-1 L12.6237437,24.65" id="Line-Copy" transform="translate(12.623744, 11.825000) rotate(-315.000000) translate(-12.623744, -11.825000) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
        
    </div>
    )
}

CloseBot.defaultProps = {
    color: "#27348B"
}
CloseBot.propTypes = {
    color: PropTypes.string
}

export default CloseBot
