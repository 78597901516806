import React, { useEffect, useContext } from 'react'
import Div100vh from 'react-div-100vh'
import { NavContext } from '../context/NavContext'
import { BotContext } from '../context/BotContext'
import { useStaticQuery, graphql } from 'gatsby'
import landBotBackground from '../../images/other/blurred_background.jpg'





import CloseBot from './CloseBot'
import { nominalTypeHack } from 'prop-types'

const windowAwait = typeof window !== "undefined" ? window : ''
const documentAwait = typeof document !== "undefined" ? document : ''

const landBots = {
    LandBot: {
        key: 'defaultLandbot',
        name: 'LandBot',
        domain: 'landbot.pro',
    },
    LandBotB: {
        key: 'defaultLandbotB',
        name: 'LandBotB',
        domain: 'landbot.pro',
    },
    LandBotForAds: {
        key: 'hubspotLandbot',
        name: 'LandBotForAds',
        domain: 'chats.landbot.io',
    },
    ServicesLandBot: {
        key: 'servicesLandbot',
        name: 'ServicesLandBot',
        domain: 'chats.landbot.io',
    },
    LocationsLandBot: {
        key: 'locationsLandbot',
        name: 'LocationsLandBot',
        domain: 'chats.landbot.io',
    },
    OfficeDesignLandBot: {
        key: 'officeDesignLandbot',
        name: 'OfficeDesignLandBot',
        domain: 'landbot.site',
    },
    PayLandBot: {
        key: 'payLandbot',
        name: 'PayLandBot',
        domain: 'landbot.pro',
    },
    Pay2LandBot: {
        key: 'pay2Landbot',
        name: 'Pay2LandBot',
        domain: 'landbot.pro',
    },
}

const LandBotContainer = () => {

    const { botShow, setBotShow, activeBot, setActiveBot, showOnLanding } = useContext(BotContext)


    // Accessing the Landbot Code from the CMS
    const landbot = useStaticQuery(graphql`
        query landBotId {
            defaultLandbot: contentfulExternalServiceIds(serviceName: {eq: "LandBot"}) {
                serviceId
            }
            defaultLandbotB: contentfulExternalServiceIds(serviceName: {eq: "LandBotB"}) {
                serviceId
            }
            hubspotLandbot: contentfulExternalServiceIds(serviceName: {eq: "LandBotForAds"}) {
                serviceId
            }
            servicesLandbot: contentfulExternalServiceIds(serviceName: {eq: "ServicesLandBot"}) {
                serviceId
            }
            locationsLandbot: contentfulExternalServiceIds(serviceName: {eq: "LocationsLandBot"}) {
                serviceId
            }
            officeDesignLandbot: contentfulExternalServiceIds(serviceName: {eq: "OfficeDesignLandBot"}) {
                serviceId
            }
            payLandbot: contentfulExternalServiceIds(serviceName: {eq: "PayLandBot"}) {
                serviceId
            }
            pay2Landbot: contentfulExternalServiceIds(serviceName: {eq: "Pay2LandBot"}) {
                serviceId
            }
        }
    `)

    const { key, domain } = Object.values(landBots).find(({ name }) => name === activeBot)
    const landbotUrl = `https://${domain}/v3/${landbot[key].serviceId}/index.html${windowAwait.location ? windowAwait.location.search : ''}`;

    const { LandbotFrameWidget } = windowAwait;


    const { menuActive, setMenuActive } = useContext(NavContext)



    const landBot = (LandbotFrameWidget) => {
        if (LandbotFrameWidget) {
            return new LandbotFrameWidget({
                index: landbotUrl,
                container: '#landBot',
            });
        }
    }

    useEffect(() => {
        if (botShow) {
            if (menuActive) {
                setMenuActive(false)
            }
            // Activate the landbot
            window.gtag('event', 'Clicked get started', { 'event_category': 'A/B Test' });
            landBot(LandbotFrameWidget)
            documentAwait.getElementsByTagName('html')[0].style.overflow = "hidden";
        }
        if (!botShow) {
            documentAwait.getElementsByTagName('html')[0].style.overflow = "scroll";
        }
        return () => {
            if (botShow) return ""
            if (!botShow) {
                documentAwait.getElementsByTagName('html')[0].style.overflow = "scroll";
            }
        };
    })

    return (
        <>
            <div
                className=" bg-secondary w-screen fixed left-0 transition flex flex-col justify-center"
                style={{
                    opacity: botShow ? 1 : 0.7,
                    height: botShow ? '100vh' : '0px',
                    zIndex: botShow ? 9999 : -9999,
                    bottom: 0,
                    background: `rgba(39, 52, 139, 0.25) ${![landBots.PayLandBot.name, landBots.Pay2LandBot.name].includes(activeBot) ? `url(${landBotBackground})` : ''}`,
                    backgroundSize: "cover",
                    display: "flex",
                    alignItems: "center",
                }}
            >

                <Div100vh id="landBot"
                    className={`my-20 border-primary shadow-md border-2 ${botShow ? "block" : "hidden"}  w-11/12  lg:w-1/2  h-full`}
                    style={{
                        backgroundColor: "#fecc02",
                    }}>
                </Div100vh>
                <CloseBot
                    setActiveBot={setActiveBot}
                    botShow={botShow}
                    setBotShow={setBotShow}
                />
            </div>


        </>
    )
}

export default LandBotContainer
